.About {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    padding: 200px 0;
    box-sizing: border-box;
}

.about-description {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.about-description > h1 {
    margin: 0;
    transform: translateY(-20px);
    opacity: 0;

    font-family: var(--title-font);
    font-size: 48px;
    font-weight: 300;
    color: var(--body-text-color);
}

.about-divider {
    position: relative;
    width: 0%;
    height: 2px;
    margin: 20px 0;

    background-color: var(--eraser);
}

.about-description > p {
    transform: translateX(40px);
    opacity: 0;

    line-height: 30px;;
    font-size: 14px;
    color: var(--body-text-color);
}

.about-headshot {
   
}

.about-headshot-image {
    position: relative;
    width: 250px;
    outline: 5px solid var(--rose);
    outline-offset: 5px;   
    opacity: 0;
    transform: translateX(-20px);
}

@media(max-width: 700px) {
    .About {
        padding: 100px 0 !important;
        flex-direction: column;
        gap: 50px !important;
    }

    .about-description {
        width: 100% !important;
        padding: 40px;
        box-sizing: border-box;
    }

    .about-headshot-image {
        width: 200px !important;
    }

    .about-divider {
        width: 0%;
    }
}