.TestamonialTile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 350px;
    padding: 20px;
    box-sizing: border-box;
    user-select: none;
    transform: scale(0.9);
    opacity: 0;

    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);

    transition: all 300ms ease;
}

.TestamonialTile:hover {
    scale: 1.05;
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
}

.tile-text {
    margin: 0;
    line-height: 22px;

    font-size: 12px;
    color: var(--body-text-color);
}

.tile-text > span {
    font-size: 20px;
    font-weight: 700;
}

.tile-name {
    margin: 20px 0 0px;
    font-family: var(--header-font);
    font-weight: 600;
    font-size: 16px;
}

.tile-grow {
    animation: tileGrow 500ms ease forwards;
    animation-delay: 200ms;
}

@keyframes tileGrow {
    100%{opacity: 1; transform: scale(1);}
}