.Performance {
    position: relative;
    margin-top: 100px;
    box-sizing: border-box;
}

.performance-hero {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 200px;
}

.performance-hero-image {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 0 15%;
    opacity: 0;
}

.performance-hero-title {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 30%;
    padding-left: 5vw;
    opacity: 0;
}

.performance-hero-title > h1 {
    margin: 0;
    transform: translateX(20px);
    opacity: 0;

    color: white;
    font-family: var(--title-font);
    font-weight: 100;
    font-size: 48px;
}

.performance-hero-title > p {
    margin: 0;
    transform: translateY(20px);
    opacity: 0;

    color: white;
    font-family: var(--body-text-font);
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
}

.performance-media-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0;
}

.performance-media-container > h1:nth-child(3) {
    margin-top: 100px;
}

.performance-media-youtube-container {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.performance-media-youtube-container > iframe {
    opacity: 0;
    width: 50%;
    aspect-ratio: 16/9;
}

.performance-media-youtube-container > iframe:nth-child(2) {
    animation-delay: 300ms;
}

.performance-media-youtube-container > iframe:nth-child(3) {
    animation-delay: 500ms;
}

.performance-media-recordings {
    padding: 10px 0;
    display: flex;
    gap: 20px;
}

.Recording {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    opacity: 0;
    
    background-color: white;
}

.Recording:nth-child(odd) {
    transform: translateX(-20px);
}

.Recording:nth-child(even) {
    transform: translateX(20px);
}

.Recording > audio {
    display: flex;
}

.Recording > p {
    margin: 0;
    text-align: center;

    font-size: 16px;
    font-weight: 500;
    font-family: var(--body-text-font);
    color: var(--body-text-color);
}

.PerformanceTitle {
    margin: 50px;
    font-family: var(--header-font);
    color: var(--body-text-color);
    transform: translateY(-20px);
    opacity: 0;
}

@media(max-width: 1100px) {
    .performance-media-youtube-container {
        flex-direction: column;
    }
    
    .performance-media-youtube-container > iframe {
        width: 90vw;
        height: 50vw;
    }

    .performance-media-container > h1 {
        font-size: 40px;
    }
}

@media(max-width: 1100px) and (min-width: 801px) {
    .performance-hero-image {
        object-position: 75% 0;
    }

    .performance-hero-title {
        width: 50%;
    }

    .performance-hero-title > h1 {
        font-size: 28px;
    }

    .performance-hero-title > p {
        font-size: 14px;
    }
}

@media(max-width: 800px) {
    .performance-hero {
        height: 100vh;
    }

    .performance-hero-image {
        object-position: 70% 0;
    }

    .performance-hero-title {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        background-color: rgba(0,0,0,0.5);
    }

    .performance-hero-title > h1 {
        font-size: 36px;
    }

    .performance-hero-title > p {
        font-size: 12px;
    }

    .performance-media-container > h1 {
        font-size: 32px;
    }

    .performance-media-recordings {
        flex-direction: column;
    }
}