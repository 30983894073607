.Teaching {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teaching-image-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;

    background-color: var(--rose);
}

.teaching-image-grid > div {
    display: flex;
    justify-content: center;
    gap: 10px;
    height: 100%;
}

.teaching-image-grid > div > img {
    height: 20vw;
    object-fit: cover;
    object-position: top;
    opacity: 0;
}

.long-image {
    width: 60%;
}

.short-image {
    width: 40%;
}

#img-1 {
    transform: translateX(-10px);
    object-position: 0 -50px;
}

#img-2 {
    transform: translateX(10px);

    animation-delay: 500ms;
}

#img-3 {
    transform: translateX(-10px);

    animation-delay: 1s;
}

#img-4 {
    transform: translateX(10px);    
    object-position: 0 -20px;

    animation-delay: 1.5s;
}

.teaching-details {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

.teaching-details-title {
    transform: translateY(-30%);
    margin: 0;
    padding: 30px 50px;
    width: fit-content;
    opacity: 0;

    background-color: white;
    border: 1px solid rgb(220,220,220);
    font-family: var(--header-font);
    color: var(--eraser);
    outline: 5px solid var(--peach);
    outline-offset: 5px;
}

.teaching-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.teaching-contact-container > h4 {
    text-align: center;
    transform: translateY(-20px);
    opacity: 0;
    
    font-family: var(--body-font);
    font-size: 20px;
    font-weight: 400;
    color: var(--body-text-color);
}

.teaching-contact-container > h4 > a {
    font-weight: 600 !important;
    color: var(--forest);

    transition: all 300ms ease;
}

.teaching-contact-container > h4 > a:hover {
    color: var(--olive);
}

.teaching-details-description {
    position: relative;
    margin-top: 50px;
    transform: translateY(20px);
    width: 50%;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    opacity: 0;
}

.teaching-fade-in-directional {
    animation: TeachingFadeInFromDirection 1s ease forwards;
}

@media(max-width: 700px) {
    .teaching-image-grid {
        padding: 10px;
    }

    .teaching-image-grid > div {
        flex-direction: column;
        align-items: center;
    }

    .teaching-image-grid > div > img {
        width: 100% !important;
        height: 200px;
    }

    .teaching-details-title {
        font-size: 20px !important;
    }

    .teaching-details-description {
        width: 80% !important;
    }

    #img-1 {
        transform: translateX(-30px);
        object-position: 0;
    }
}

@keyframes TeachingFadeInFromDirection {
    100%{opacity: 1; transform: translateY(-50%);}
}